@import "vars_aurelio";

.message {
  border-radius: 7px;
  border: 1px solid #edeef0;
  background-color: #f4f5f6;
  margin-bottom: 5px;
  color: #2a2e31;
  display: flex;
  padding: 10px;
  min-height: 50px;
  align-items: center;

  &-collapsed {
    border: 1px solid #edeef0;
    background-color: #f4f5f6;
    margin-bottom: 5px;
    color: #2a2e31;
    color: $dark-text-color;
    padding: 0;
    align-items: center;
    margin-top: -10px;
    border-radius: 0 0 7px 7px;
    border-top: none;
  }

  &.button {
    min-height: 30px;
  }

  &-info {
    font-size: 14px;
    color: $light-text-color;
    float: left;
    width: calc(100% - 50px);
  }

  .message-icon {
    i {
      height: initial;
      width: initial;
      border: none;
      margin-left: 0;
      background: transparent;
      padding: 0;
      font-size: 20px;
    }
  }

  &-icon {
    width: 25px;
    color: $main-color;
    cursor: pointer;

    i {
      height: 25px;
      width: 25px;
      border: 1px solid #edeef0;
      background: white;
      border-radius: 30px;
      padding: 5px 10px;
      font-size: 12px;
      color: $main-color;
      &:hover {
        border-color: #e0e2e5;
      }
    }
  }

  &-icon.small {
    width: initial;
    color: inherit;
    font-size: 14px;
    font-weight: 500;

    span {
      color: $dark-text-color;
      margin-left: 5px;
    }
  }

  &-info h3 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    color: $dark-text-color;
  }
}